import { FC } from 'react';
import PSButton from '@elements/PSButton';
import PSForm, { PSFormField, PSTextbox } from '@elements/PSForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ShopifyLoginFormProps, ShopifyLoginFormValues } from './types';
import ShopifyIcon from '@assets/icons/shopify-icon.svg';
import { domainNormalizer } from 'core/helpers/normalize';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  storeName: yup.string().required('This field is required!').trim(),
});
const ShopifyLoginForm: FC<ShopifyLoginFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shopify-login' });
  const { t: cT } = useTranslation('common');
  const methods = useForm<ShopifyLoginFormValues>({
    defaultValues: { storeName: '' },
    resolver: yupResolver(schema),
  });
  const isDisableSignInButton = methods.formState.isDirty;

  return (
    <PSForm onSubmit={methods.handleSubmit(onSubmit)}>
      <PSFormField
        control={methods.control}
        component={PSTextbox}
        name="storeName"
        placeholder={cT('shopifyDomain')}
        placeholderColor="#777777"
        errorContainerStyle={{
          initial: {
            height: 12,
          },
          onError: {
            height: 18,
            marginBottom: 12,
          },
        }}
        normalize={domainNormalizer}
      />

      <PSButton
        type="submit"
        icon={ShopifyIcon}
        block
        color="shopify"
        className="!p-0.5 !rounded-md !text-sm"
        textClassName="w-full"
        iconClassName="bg-white p-2 rounded"
        disabled={!isDisableSignInButton}
      >
        {t('shopifySubmitButton')}
      </PSButton>
    </PSForm>
  );
};

export default ShopifyLoginForm;
