import React, { FC } from 'react';
import styles from './RegisterModal.module.scss';
import Logo from '@assets/icons/IconLogo.svg';
import cn from 'classnames';
import { useRouter } from 'next/router';

const RegisterModal: FC = () => {
  const router = useRouter();

  return (
    <div className={styles.signInBetaModalContainer}>
      <div className={styles.container}>
        <Logo width="54" height="54" viewBox="0 0 54 55" />
        <h2>Popupsmart V2 - Beta</h2>
        <p className={styles.p1}>
          Thanks for your interest in <b>Popupsmart Beta.</b> We still recommend
          registering <b>the current version.</b>
        </p>
        <p className={styles.p2}>
          If you like living on the edge and try out our shiniest features, you
          can continue and register our beta.
        </p>
        <div className={styles.btnContainer}>
          <a
            className={cn(styles.btn, styles.gray)}
            onClick={() => {
              router.push('/register');
            }}
          >
            Register Beta
          </a>
          <a
            className={cn(styles.btn, styles.smart)}
            href="https://dashboard.popupsmart.com/register"
          >
            Register Current Version
          </a>
        </div>
      </div>
    </div>
  );
};

export default RegisterModal;
