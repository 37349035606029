import PSButton from '@elements/PSButton';
import PSForm, { PSFormField, PSTextbox } from '@elements/PSForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LoginFormProps, LoginFormValues } from './types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('This field should be a valid email address.')
    .required('This field is required!')
    .trim(),
  password: yup.string().required('This field is required!').trim(),
});
const LoginForm: FC<LoginFormProps> = ({ onSubmit, isButtonLoading }) => {
  const { t } = useTranslation('common');
  const methods = useForm<LoginFormValues>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schema),
  });
  const isDisableSignInButton = methods.formState.isDirty;
  return (
    <PSForm onSubmit={methods.handleSubmit(onSubmit)}>
      <PSFormField
        control={methods.control}
        component={PSTextbox}
        name="email"
        placeholder={t('email')}
        placeholderColor="#777777"
        errorContainerStyle={{
          initial: {
            height: 12,
          },
          onError: {
            height: 18,
            marginBottom: 12,
          },
        }}
      />
      <PSFormField
        control={methods.control}
        component={PSTextbox}
        name="password"
        type="password"
        placeholder={t('password')}
        placeholderColor="#777777"
        errorContainerStyle={{
          initial: {
            height: 12,
          },
          onError: {
            height: 18,
            marginBottom: 18,
          },
        }}
      />

      <PSButton
        type="submit"
        block
        disabled={!isDisableSignInButton || isButtonLoading}
        loading={isButtonLoading}
        id="loginbutton"
        className="!text-sm h-[38px] !rounded-md"
      >
        {t('signIn')}
      </PSButton>
    </PSForm>
  );
};

export default LoginForm;
