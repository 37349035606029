export const callbackUrl = (
  callback: string | string[] | undefined,
): string => {
  if (typeof callback === 'string') {
    return callback;
  }
  if (Array.isArray(callback)) {
    return callback[0];
  }
  return '/';
};
