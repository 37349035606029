import React, { useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './Login.module.scss';

import Logo from '@assets/icons/popupsmart-rounded.svg';
import PSButton from '@elements/PSButton';
import { LoginFormValues, ShopifyLoginFormValues } from './types';
import RegisterModal from '@components/RegisterModal';
import PsModal from '@components/PsModal';
import {
  useAccountsLazyQuery,
  useGetUrlByShopifyDomainLazyQuery,
  useCustomerGoogleLoginMutation,
  useLoginMutation,
  useMeLazyQuery,
  useShopifyLinkAccountMutation,
} from 'src/generated/graphql';
import { NextPageWithAuth } from 'global';
import { callbackUrl } from '@utils/callbackUrl';
import store from '@utils/store';
import { toast } from 'react-toastify';
import ShopifyIcon from '@assets/icons/shopify-icon.svg';
import LoginForm from './LoginForm';
import ShopifyLoginForm from './ShopifyLoginForm';
import { domainNormalizer } from 'core/helpers/normalize';
import { useDispatch } from 'react-redux';
import { setAuthUser } from '@connectors/auth/actions';
import { useTranslation } from 'react-i18next';

const clientId = process.env.NEXT_PUBLIC_SHOPIFY_CLIENT_ID;
const dashboardUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;
const accessScope = process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_SCOPE;
const Login: NextPageWithAuth = () => {
  const router = useRouter();
  const { t } = useTranslation('pages', { keyPrefix: 'login' });
  const { t: shopifyT } = useTranslation('pages', {
    keyPrefix: 'shopify-login',
  });
  const { t: common } = useTranslation('common');
  const {
    callbackUrl: callback,
    shop,
    code,
    host,
    hmac,
    timestamp,
  } = router.query;
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [showShopifyForm, setShowShopifyForm] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [getAccounts] = useAccountsLazyQuery();
  const redirectDashboard = () => {
    // TODO: login script is cached replace script with google library or useScript and remove this
    if (window.location.href.includes('register')) {
      const url = callback
        ? `/onboarding?callbackUrl=${callback}&flow=register`
        : '/onboarding?flow=register';

      router.push(url);
      return;
    }
    let redirectPath = callbackUrl(callback ? callback : '/');
    if (shop && code) {
      const redirectUrl = `${dashboardUrl}/shopify/offline`;
      window.location.href = `https://${shop}/admin/oauth/authorize?client_id=${clientId}&scope=${accessScope}&redirect_uri=${redirectUrl}`;
    }
    router.push(redirectPath);
  };

  const [getMe] = useMeLazyQuery({
    onCompleted: () => {
      getAccounts({
        fetchPolicy: 'network-only',
        onCompleted: () => {
          setIsButtonLoading(false);
        },
      });
    },
    onError: () => setIsButtonLoading(false),
  });

  useEffect(() => {
    if (containerRef.current) {
      const { accounts } = (window as any).google || {};

      accounts?.id.renderButton(containerRef.current, {
        context: 'login',
        theme: 'filled_blue',
        text: 'signup_with',
        size: 'large',
        width: containerRef.current?.clientWidth
          ? containerRef.current.clientWidth
          : '100%',
      });
    }
  }, [containerRef.current]);

  useEffect(() => {
    const accessToken = router.query?.withGoogle as string;
    if (accessToken) {
      window.onload = () => {
        const currentGoogleIframeContainer =
          document.querySelector<HTMLIFrameElement>(
            'iframe[src^="https://accounts.google.com/gsi/iframe"]',
          );

        if (currentGoogleIframeContainer) {
          currentGoogleIframeContainer.style.display = 'none';
        }
      };

      customerGoogleLogin({
        variables: {
          accessToken,
        },
        onCompleted: (data) => {
          dispatch(setAuthUser(data.customerGoogleLogin));
          setTimeout(() => {
            handleRedirectGoogleRegister(
              data.customerGoogleLogin.isRegisteredUser,
            );
          }, 15);
        },
      });
    }
  }, [router]);

  const dispatch = useDispatch();
  const [loginMutation] = useLoginMutation();
  const [customerGoogleLogin] = useCustomerGoogleLoginMutation();
  const [shopifyLink] = useShopifyLinkAccountMutation();

  const generateRegisterUrl = (callback?: string | string[]) => {
    const register = '/register';
    const { shop, code, host, hmac, timestamp } = router.query;

    if (shop && code && host && hmac && timestamp)
      return `${register}?shop=${shop}&code=${code}&host=${host}&hmac=${hmac}&timestamp=${timestamp}`;
    else if (callback)
      return `${register}?callbackUrl=${callbackUrl(callback)}`;

    return register;
  };

  const onSubmit = (values: LoginFormValues) => {
    setIsButtonLoading(true);
    loginMutation({
      variables: {
        email: values.email.toLowerCase(),
        password: values.password,
      },
      onCompleted: async (e) => {
        store.set('user', {
          token: e.login.accessToken,
          refreshToken: e.login.refreshToken,
        });
        if (shop && code && host && hmac && timestamp) {
          await shopifyLink({
            variables: {
              code: code as string,
              shop: shop as string,
              host: host as string,
              hmac: hmac as string,
              timestamp: timestamp as string,
            },
          });
        }
        getMe();
        setIsButtonLoading(false);
        redirectDashboard();
      },
      onError: (e) => {
        toast.error(e.message);
        setIsButtonLoading(false);
      },
    });
  };

  const onClickSignInWithShopify = () => {
    setShowShopifyForm((state) => !state);
  };
  const [getUrlByShopifyDomainQuery] = useGetUrlByShopifyDomainLazyQuery();

  const onSubmitShopifyLogin = async (values: ShopifyLoginFormValues) => {
    const clientId = process.env.NEXT_PUBLIC_SHOPIFY_CLIENT_ID;
    const dashboardUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;
    const accessScope = process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_SCOPE;

    const getUrlByShopifyDomainResponse = await getUrlByShopifyDomainQuery({
      variables: { domain: domainNormalizer(values.storeName.trim()) },
      onError: (error) => {
        toast.error(
          <div className="cursor-default">
            <span>{error.message}</span>
            <br />
            <span>
              Install
              <a
                className="underline mx-1 hover:opacity-80 hover:text-bluesmart"
                href="https://apps.shopify.com/popupsmart"
                target="_target"
              >
                Popupsmart App
              </a>
              to login.
            </span>
          </div>,
        );
      },
    });

    const shopUrl = getUrlByShopifyDomainResponse.data?.getUrlByDomain.url;

    if (shopUrl) {
      window.location.href = `https://${shopUrl}/admin/oauth/authorize?client_id=${clientId}&scope=${accessScope}&redirect_uri=${dashboardUrl}/shopify/login&grant_options[]=per-user`;
    }
  };

  const handleRedirectGoogleRegister = (isRegisteredUser: boolean) => {
    if (isRegisteredUser) {
      redirectDashboard();
      return;
    }

    router.push('/onboarding?flow=register');
  };
  return (
    <>
      <Head>
        <title>Login - Popupsmart: A Better Popup Builder</title>
      </Head>
      <div className={styles.login}>
        <div className={styles.formContainer}>
          <div className={styles.logo}>
            <Logo width="54" height="54" viewBox="0 0 48 48" />
          </div>
          <div className={styles.title}>{common('signIn')}</div>
          {!showShopifyForm ? (
            <>
              <div className={styles.subTitle}>
                {t('description')}
                {/* You can sign in via your email, Gmail or Shopify account */}
              </div>
              <LoginForm
                onSubmit={onSubmit}
                isButtonLoading={isButtonLoading}
              />
              <div className={styles.linkContainer}>
                <Link
                  className="cursor-pointer text-sm"
                  href={generateRegisterUrl(callback)}
                >
                  {t('registerLinkText')}
                </Link>

                <div id="forgotpasswordlink">
                  <Link href="/forgot-password">{t('forgotPassword')}</Link>
                </div>
              </div>
              <div className={styles.googleButtonWrapper}>
                <div
                  ref={containerRef}
                  id="google-login-btn"
                  className={styles.googleBtn}
                />
              </div>
              {!shop && !code && !host && !hmac && !timestamp && (
                <PSButton
                  type="button"
                  icon={ShopifyIcon}
                  onClick={onClickSignInWithShopify}
                  block
                  color="shopify"
                  className="my-2 !p-0.5 !rounded-md !text-sm"
                  textClassName="w-full"
                  iconClassName="bg-white p-2 rounded"
                >
                  {t('shopifyButton')}
                </PSButton>
              )}
            </>
          ) : (
            <>
              <div className={styles.subTitle}>{shopifyT('description')}</div>
              <ShopifyLoginForm onSubmit={onSubmitShopifyLogin} />
              <button
                className="text-xs font-normal mt-2 text-left leading-4"
                onClick={() => setShowShopifyForm(false)}
              >
                {shopifyT('backToLoginOptions')}
              </button>
            </>
          )}
        </div>
      </div>
      <PsModal
        id="RegisterModal"
        size={650}
        radius="32px"
        className="!top-[25%] md:!top-0"
      >
        <RegisterModal />
      </PsModal>
      <Script
        src="https://accounts.google.com/gsi/client"
        id="google-login"
        key="google-login"
        async
        defer
        onReady={() => {
          const { accounts } = (window as any).google || {};
          accounts?.id.initialize({
            client_id: process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID,
            callback: (response: any) => {
              const accessToken = response.credential;
              customerGoogleLogin({
                variables: {
                  accessToken,
                },
                onCompleted: (data) => {
                  dispatch(setAuthUser(data.customerGoogleLogin));
                  setTimeout(() => {
                    handleRedirectGoogleRegister(
                      data.customerGoogleLogin.isRegisteredUser,
                    );
                  }, 15);
                },
              });
            },
          });

          accounts?.id.renderButton(
            document.getElementById('google-login-btn'),
            {
              context: 'login',
              theme: 'outline',
              text: 'signup_with',
              size: 'large',
              logo_alignment: 'center',
              width: containerRef.current?.clientWidth
                ? containerRef.current.clientWidth
                : '100%',
            },
          );

          accounts?.id.prompt(); // also display the One Tap dialog
        }}
      />
    </>
  );
};
Login.isPublicRoute = true;
export default Login;
